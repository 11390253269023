<template>
  <div>
    <slot class="slot-class"></slot>
    <img v-if="currentPicSrcData!==''" class="trade_steps" :src="currentPicSrcData" alt="交易须知">
  </div>
  
</template>

<script>
export default {
  props:{
    currentPicSrcData:{
      type:String,
      required:true,
      default:""
    }
  }
}
</script>

<style lang="less" scoped>
.slot-class{
  height: 20px;
  font-family: MicrosoftYaHei;
  font-size: 15px;
  color: #222222;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}
.trade_steps{
  margin-top: 20px;
  width: 860px;
}
</style>